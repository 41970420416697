import { Component, OnInit } from '@angular/core';
import { ListingService } from 'src/app/services/listing.service';
import { listing } from 'src/app/services/listing.service';
import { PixelService } from 'ngx-pixel';
import { faAngleRight, faQuoteLeft, faQuoteRight, faUserCircle, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  faCaret=faAngleRight;
  showData=false;

  constructor(public listingService : ListingService, private pixel: PixelService) { }
  // activeListings:Array<listing>;
  counties=["McLennan", "Bosque", "Hill", "Bell", "Falls", "Hamilton", "Limestone", "Coryell"];
  recentListings;
  landListings;
  resPromise: Promise<boolean>;
  landPromise: Promise<boolean>;
  quoteLeft = faQuoteLeft;
  quoteRight = faQuoteRight;
  user = faUserCircle;
  location = faMapMarkerAlt;
  recentListingCount=0;
  landListingCount=0;


  testimonies=[
    {body: "Throughout the entire selling process, Esther was caring, creative, conscientious and motivated. Her work-ethic was extraordinary. She was easy to stay in contact with and would return emails and phone calls promptly. At times it felt as though I was her only client even though I knew she had a very busy schedule. In addition to the selling/marketing of the property she is very knowledgeable in helping to take care of the necessary legal issues and paper work to close the deal.One more completely satisfied customer.", author: "Ted J.", location : "Valley Mills, TX – Bosque County"},
    {body: "I have bought, built, and sold a lot of properties in my life but never had an agent that could equal Esther Easter standards. She knew when to talk and when to listen. I can’t say enough about her professionalism. Thank You and we’ll do business again.", author: "Tim R.", location: "Clifton, TX – Bosque County"},
    {body: "My previous experiences with a Realtor in attempting to buy land was not fun to say the least. Both attempts failed and I was discouraged with the experiences I had.  Then I came across Esther’s listing and began to work with her. Her honest approach and constant updates restored my belief that good people do exist and work hard in this profession.  She has a great personality and will help you navigate your purchase.", author: "Mark S.", location: "Hubbard - Limestone County"},
    {body: "When we wanted to buy some acreage in Crawford, the decision was simple: Use Esther. Esther is THE local land agent who knows the ins and outs of Crawford. And, sure enough, Esther was right every step of the way—she was right there by our side and right in her assessment of all sorts of questions we had about land issues. Because of Esther’s expertise and in depth diligence, we are now the proud owners of land in Crawford. Esther truly went above and beyond—and to her we are deeply grateful!", author: "Erika W.", location: "Crawford - McLennan County"},
    {body: "We had no idea how much effort Esther had put into selling a piece of property. She knew the sellers, neighbors and the land. She had spent time herself on the property. The pictures and the drone video made the package complete. We felt like we knew the property before she gave us a complete tour. She knew all about easements, surveys and contracts. She works hard for the seller and just as hard for the buyer. We now have a realtor and a good friend. We love our new ranch.", author: "Browny & Pam C.", location: "Valley Mills, TX – Bosque County"},
    {body: "I’ve worked with quite a few realtors over the years.  None of them provided the quality of service we received from Esther from start to finish.  She really did an exceptional job!!", author: "Barry C.", location: "Aquilla –  Hill County"},
    {body: "I cannot say enough about this wonderful lady!! She is a class act all the way around! She is very professional and knows the business inside and out. She made the business of selling our house easy and less stressful. I’ve known Esther for 27 years. She is a woman of integrity and intelligence, and I’m honored to know her! If you are looking for a realtor in central Texas, I HIGHLY recommend Esther Easter!", author: "Nancy D.", location: "China Spring, TX – Bosque County"},
    {body: "When we first met Esther, I knew she was the realtor we needed to sell our property. My instincts were proved to be correct. Esther worked tirelessly with us to resolve problems that came up during the process and was extremely knowledgeable about the legalities needed to sell our acreage. I recommend her to anyone needing a realtor. May God continue to bless her endeavors.", author: "Rusty J.", location: "Valley Mills, TX – Bosque County"},
    {body: "I needed to sell my property from out of state, so I had to develop a relationship with an agent without even meeting in person. From the start, Esther earned my trust, my respect and my gratitude, for being 100% hands on and taking ownership of the process, attending to every detail, and getting better than asking price on the sale. She made it easy for me and was responsive to every requirement I had. When I am looking for property in Texas again, Esther is the only agent I want to represent me", author: "Rollin L", location: "China Spring, TX – Bosque County"},
    {body: "We worked with Esther in selling our home and also purchasing land on which to build our dream home. Esther was genuine and professional, and we trusted her completely to guide us through each step. She was responsive to any questions or concerns and had our best interest at heart. It is evident that she knows the real estate business inside and out which made us feel comfortable and confident with each decision. Whether buying or selling, I would wholeheartedly recommend working with Esther.", author: "Laurie H.", location: "China Spring – McLennan County"},
    {body: "Esther Easter sold a 42-acre piece of Hill Country (Clifton) for us 12 hours after she listed it.  Then she sold a 1500+ sf home in the China Spring school district in one day, I highly recommend her!!", author: "Ron D.", location: "Bosque and McLennan Counties"},
    {body: "Esther has proven to be an extraordinary realtor, business woman, and trusted friend. When we began our search for our dream home/ranch we were in need of a devoted and knowledgeable realtor. Esther proved to supersede our expectations. She led us through the buying process and it was evident she had done her research on the properties she had listed. Not only did she provide the assistance of going through the tedious process of purchasing land but she made it seem as though we were her only clients.  Esther is an absolute professional and will forever hold our recommendation for anyone seeking to make Texas their home.", author: "Salvador C.", location: "Aquilla – Hill County"},
    {body: "I did NOT want to sell my home but for health reasons I needed to.  A friend recommended Esther so I made the call for an appointment.  I was so nervous and scared but from the moment I met Esther I felt a connection and immediately felt at peace.  I now know that God put Esther in my life.  She made the whole process so easy and she was so patient with me. I knew I had made the right decision.  She sold my home and closed within one month.  I highly recommend Esther Easter if you are buying or selling.  Not only is she a fabulous real estate agent, but she is an amazing person!", author: "Bobbye S.", location: "China Spring – McLennan County"},
    {body: "I contacted Esther to list our home after listing it with a different realtor and not having two showings in six months. Esther worked very hard to get our house listed and even sent a photographer, landscaper and uploaded a drone photo to her webpage. She went above and beyond.  Even after the sale was completed she still was doing things that she was not required to do for us.  I want to add that she sold our house the very first day on the first showing for full asking price!", author: "Annette B.", location: "China Spring - McLennan County"},

  ];
  ngOnInit(): void {
    
    this.pixel.track('PageView');
    this.listingService.getRecents(10).subscribe(recents=>{
      this.recentListings=recents['value'];
      this.recentListingCount=recents['@odata.count'];
      this.resPromise=Promise.resolve(true);
    })
    this.listingService.getLand(10).subscribe(recents=>{
      this.landListings=recents['value'];
      this.landListingCount=recents['@odata.count'];
      this.landPromise=Promise.resolve(true);
    })
    

  
  }
  

}
