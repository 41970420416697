import { Component, OnInit, Inject } from '@angular/core';
import { faEnvelope, faMobile, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { DOCUMENT } from '@angular/common';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import { NgZone, ViewChild} from '@angular/core';
import {take} from 'rxjs/operators';
import { PixelService } from 'ngx-pixel';
import {} from 'googlemaps';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  title = 'DEP Office';
  lat = 31.583706544091097;
  lng = -97.30973441784992;
  envelope=faEnvelope;
  phone=faMobile;
  location=faMapMarkerAlt;
  contactForm:FormGroup;
  @ViewChild('map') mapElement: any;
  map: google.maps.Map;
  style= [ 
    // {
    //    //don't show highways lables on the map
    //    featureType: 'road.highway',
    //    elementType: 'labels',
    //    stylers: [
    //       {visibility: "off"}
    //    ]
    // }, 
    {
       //set highway colors/brightness/saturation
       featureType: "road",
       elementType: "geometry.fill",
       stylers: [
          { color: '#646d6f' },
          { visibility: "on" }, 

       ]
    }, 
    {
      //set highway colors/brightness/saturation
      featureType: "landscape",
      elementType: "geometry.fill",
      stylers: [
         { color: '#e0e1e2' },
         { visibility: "on" }, 

      ]
   }, 
   {
    //set highway colors/brightness/saturation
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [
       { color: '#526381' },
       { visibility: "on" }, 

    ]
 }, 
    
    // other elements style here
 ];

  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  


  constructor(@Inject(DOCUMENT) private document: Document, private fb: FormBuilder, private _ngZone: NgZone, private pixel:PixelService) {
    this.contactForm = this.fb.group({
      subject: ['', [Validators.required]],
      body: ['', [Validators.required]],
      cell: ['', [Validators.required]],
    });
   }

  ngOnInit(): void {
    
  }
  ngAfterViewInit(){
    const mapProperties = {
      center: new google.maps.LatLng(this.lat, this.lng),
      zoom: 14,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapId: "53c96a872b075a9e"
    };
    console.log(this.mapElement)
    this.map = new google.maps.Map(this.mapElement.nativeElement,    mapProperties);
    var marker = new google.maps.Marker({
      position: new google.maps.LatLng(this.lat, this.lng),
      map: this.map,
      title: "DEP Office"
    });
  }
  // mapReady(map) {
  //   map.setOptions({
  //     mapId: '6dd924bb1e4df7c5',
  //   });
  // }
  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }

  goToUrl(): void {
    //fire pixel
    this.pixel.track('Contact');
    let contact="%0D%0A%0D%0ACell Contact: "+this.contactForm.value.cell;
    let mailString="mailto:me@esthereaster.com?subject="+this.contactForm.value.subject+"&body="+this.contactForm.value.body.replace(/\n/g, "%0D%0A")+contact;
    this.document.location.href = mailString;
}
}
