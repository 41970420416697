<div class=about>
    <div class=title>Esther Easter</div>
    <div class=accredations>BROKER, ACCREDITED LAND CONSULTANT®, GRI, SRS, ABR</div>
    <div class=body>
        <div class="description flex">
            <p>Double E Properties was forged in 2017 by Esther Easter who, after
selling real estate in the Central Texas area for several years, sought to create a
brokerage focused on the providing the highest level of personal service and
commitment through the most complex real estate transactions.
Esther is a life-long Texan who is always ready to serve her clients with
their real estate needs, implementing dynamic marketing strategies with cutting-
edge technology across multiple platforms to garner maximum exposure for
sellers. She also works diligently with buyers seeking to invest in real estate and
has handled a variety of transactions across eight Central Texas counties
ranging from two to over 1,300 acres comprised of:</p>
<ul>
    <li>Residential Luxury Estates</li>
    <li>Ranches</li>
    <li>Farms</li>
    <li>Riverfront and Lakefront Properties</li>
    <li>Recreational and AG land</li>
    <li>High-fence deer ranches</li>
    <li>Investment Sales</li>
    <li>Land development</li>
    <li>1031 Deferred Tax Exchange</li>
</ul>

<p>Esther strives to earn her clients’ trust through dedication, perseverance,
and hard work. Her attention to detail through each transaction helps to attain a
positive experience for both sides. Her passion to best serve her clients has
prompted her to pursue real estate education above that required for licensure.
She is a licensed Texas Real Estate Broker who has also earned the Accredited
Land Consultant (ALC) Designation, considered by The REALTORS® Land
Institute to be “the most prestigious, the most experienced, and the highest
performing land sales experts in their area.”</p>
<p>In addition, Esther has also earned the Graduate REALTOR® Institute
(GRI) designation, completing in-depth training on the technical aspects of real
estate. She also holds a Seller's Representative Specialist (SRS) designation
conferred by the Council of Real Estate Brokerage Managers and an Accredited
Buyer's Representative (ABR) designation conferred by the Real Estate Buyer's
Agent Council.</p>
<p>Esther would be humbled for the opportunity to serve your real estate
needs. Call today for a private consultation on your property.</p>

        </div>
        <div class=bio>
        </div>
    </div>
    <div class=description>
       <!-- <p>In addition to being a full-time real estate professional, Esther is a business owner of more than 20 years. Her business provides consulting/billing services to rural water supply corporations. She is involved in the day-to-day operations and her affiliation with the water corporations furthers her knowledge and interest of providing water to rural areas.</p> -->

    <!-- <p>Esther lives in a rural community with her husband of 29 years.  She enjoys spending time with her husband and their two adult children when they visit.  She loves getting outdoors often for running, cycling, kayaking, hunting and fishing.</p> -->

    <p>*Member of the REALTORS® Land Institute, National Association of REALTORS®,  Texas Association of REALTORS®, Waco Association of REALTORS®, and Arlington Board of REALTORS®.</p>
     
    </div>
    
</div>
