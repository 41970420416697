<div class="welcome">
    <app-search-widget></app-search-widget>    
</div>
<div class=banner><span class=homes>Homes. </span><span class=land>Land. </span><span class=texas>TEXAS.</span></div>

<!-- <div class="about">
    <div class="bio"></div>
    <div class="bio-desc">
        <h1>Meet Esther</h1>
        <p>Esther Easter started Double E Texas Properties, LLC in 2017 after working in the Central Texas area for several years, primarily as a Ranch and Land Agent. She is a life-long Texan with a love for rural living and the great outdoors and is always ready to serve her clients with their real estate needs. With the use of dynamic marketing/advertising strategies and cutting-edge technology, she will market properties for maximum exposure for sellers. She also works diligently with buyers seeking to invest in real estate and she has handled a variety of transactions, spanning eight counties, ranging from two to 1,300+ acres including: residential, land, ranches, farms, river front & lake front properties, recreational properties, and high-fence deer ranches.</p>
        <a class="more-link" routerLink="/about">Learn More<fa-icon class="more-caret" [icon]="faCaret" size="lg"></fa-icon></a>

    </div>
</div> -->

<div class=listings *ngIf="resPromise | async">
    <h1>New in Residential</h1>
    <div class="listings-row" >
        <app-listing *ngFor="let listing of recentListings; index as i;" [listing]=listing>
        </app-listing>
        <div *ngIf="this.recentListingCount > 10" class=view-more><a routerLink="/search" [queryParams]="{definedSearch:'recents'}">View More</a></div>
    </div>
</div>
<div class=listings *ngIf="landPromise | async">
    <h1>Featured in Land & Ranch</h1>
    <div class="listings-row" >
        <app-listing *ngFor="let listing of landListings; index as i;" [listing]=listing>
        </app-listing>
        <div *ngIf="this.landListingCount > 10" class=view-more><a routerLink="/search" [queryParams]="{definedSearch:'land'}">View More</a></div>
    </div>
</div>

<div class="areas">
    <h1>Counties Served</h1>
    <div class=counties>
        <div class=county *ngFor="let county of counties; index as i">
            {{county}}
            <span class=dot *ngIf="i!=counties.length-1">&#8226;</span>
        </div>
    </div>
</div>

<div class="testimonials">
    <h1>Testimonials</h1>
    <div class=testimonies>
        <div class=testimony *ngFor="let testimony of testimonies; index as i">
            <div class=content>
                <fa-icon class="quote" [icon]="quoteLeft" size="sm"></fa-icon><p>{{testimony.body}}</p><fa-icon class="quote right" [icon]="quoteRight" size="sm"></fa-icon>
            </div>
            <div class=bottom><div class=author><fa-icon class="t-icon" [icon]="user" size="sm"></fa-icon>{{testimony.author}}</div><div class=location><fa-icon class="t-icon" [icon]="location" size="sm"></fa-icon>{{testimony.location}}</div>
        </div>
    </div>
</div>
