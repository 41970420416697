import { Component, OnInit } from '@angular/core';
import { ListingService } from 'src/app/services/listing.service';
import { listing, query } from 'src/app/services/listing.service';

@Component({
  selector: 'app-listings',
  templateUrl: './listings.component.html',
  styleUrls: ['./listings.component.scss']
})
export class ListingsComponent implements OnInit {
  // activeListings:Array<listing>;
  // soldListings:Array<listing>;
  crit:query = {city: ["Valley Mills"]};
  showData=false;

  constructor(public listingService : ListingService) { }

  get activeListings(){
    return this.listingService.getActiveListings();
        
  }
  get soldListings(){
    return this.listingService.getActiveListings();
        
  }


  ngOnInit(): void {
    
  }

}
