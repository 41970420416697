import { Component, OnInit } from '@angular/core';
import { ListingService } from 'src/app/services/listing.service';
import { ActivatedRoute, Router } from '@angular/router';
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { FormBuilder } from '@angular/forms';
interface Option {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  listings;
  queryCount;
  dataPromise:Promise<Boolean>;
  pageIndex;
  top=50;
  angle=faAngleRight;
  orderBy;
  searchQuery;

  types: Option[] = [
    {value: 'SingleFamilyResidence', viewValue: 'Single Family'},
    {value: 'condos', viewValue: 'Condos'},
    {value: 'multi-family', viewValue: 'Multi Family'},
    {value: 'commercial', viewValue: 'Commercial'},
    {value: 'Land', viewValue: 'Land'},
    {value: 'Residential', viewValue: 'Residential'},
    {value: 'Farm', viewValue: 'Farm'},
    {value: 'income', viewValue: 'Income'},
    {value: 'com-lease', viewValue: 'Com Lease'},
    {value: 'townhome', viewValue: 'Town Home'},
    {value: 'condos', viewValue: 'Condos'},
    {value: 'res-lease', viewValue: 'Res Lease'},
    {value: 'farm-ranch', viewValue: 'Farm & Ranch'},
    {value: 'pending', viewValue: 'Pending'},
    {value: 'contingent', viewValue: 'Contingent'},
  ];
  beds: Option[]= [
    {value: '', viewValue: 'Any'},
    {value: '1', viewValue: '1+'},
    {value: '2', viewValue: '2+'},
    {value: '3', viewValue: '3+'},
    {value: '4', viewValue: '4+'},
    {value: '5', viewValue: '5+'},
    {value: '6', viewValue: '6+'},
  ];
  baths: Option[] = [
    {value: '', viewValue: 'Any'},
    {value: '1', viewValue: '1+'},
    {value: '1.5', viewValue: '1.5+'},
    {value: '2', viewValue: '2+'},
    {value: '2.5', viewValue: '2.5+'},
    {value: '3', viewValue: '3+'},
    {value: '4', viewValue: '4+'},
    {value: '5', viewValue: '5+'},
  ];
  prices: Option[] = [
    {value: '25000', viewValue: '$25,000'},
    {value: '50000', viewValue: '$50,000'},
    {value: '75000', viewValue: '$75,000'},
    {value: '100000', viewValue: '$100,000'},
    {value: '125000', viewValue: '$125,000'},
    {value: '150000', viewValue: '$150,000'},
    {value: '175000', viewValue: '$175,000'},
    {value: '200000', viewValue: '$200,000'},
    {value: '250000', viewValue: '$250,000'},
    {value: '300000', viewValue: '$300,000'},
    {value: '350000', viewValue: '$350,000'},
    {value: '400000', viewValue: '$400,000'},
    {value: '450000', viewValue: '$450,000'},
    {value: '500000', viewValue: '$500,000'},
    {value: '550000', viewValue: '$550,000'},
    {value: '600000', viewValue: '$600,000'},
    {value: '650000', viewValue: '$650,000'},
    {value: '700000', viewValue: '$700,000'},
    {value: '800000', viewValue: '$800,000'},
    {value: '900000', viewValue: '$900,000'},
    {value: '100000', viewValue: '$1,000,000 +'}
  ];


  constructor(public listingService : ListingService, private route:ActivatedRoute, private router: Router, private fb: FormBuilder) {
    this.searchQuery = this.fb.group({
      area: [''],
      type: [''],
      beds: [''],
      baths: [''],
      min: [''],
      max: [''],
    });
   }


  getResults(area, type, beds, baths, min, max, top, skip, orderBy){
    this.listingService.searchQuery(area, type, beds, baths, min, max, top, skip, orderBy).subscribe(data=>{
      // console.log(data);
      this.listings=data['value'];
      this.queryCount=data['@odata.count'];
      this.dataPromise=Promise.resolve(true);
      //TODO: if search doesn't return anything
    })
        
  }
  getDefined(search){
    if(search=='recents'){
      this.listingService.getRecents().subscribe(data=>{
        this.listings=data['value'];
        this.queryCount=data['@odata.count'];
        this.orderBy='OnMarketTimestamp desc';
        this.dataPromise=Promise.resolve(true);
      });
    }
    else if(search=='land'){
      this.listingService.getLand().subscribe(data=>{
        this.listings=data['value'];
        this.queryCount=data['@odata.count'];
        this.orderBy='OnMarketTimestamp desc';
        this.dataPromise=Promise.resolve(true);
      });
    }
    
  }

  ngOnInit(): void {
    let area;
    let type;
    let beds;
    let baths;
    let min;
    let max;
    let skip;
    this.orderBy;
    this.route.queryParams
      .subscribe(params => {
        //check for pre-set searches
        // console.log(params);
        if(params.definedSearch){
          this.getDefined(params.definedSearch);
        }
        else{
          // console.log(params); // { orderby: "price" }
          area = params.area;
          this.searchQuery.controls.area.setValue(params.area);
          type = params.type;
          if(params.type){
            let typeArray=params.type.replace(/'/g, '').split(',');
            this.searchQuery.controls.type.setValue(typeArray);
          }
          
          beds = params.beds;
          this.searchQuery.controls.beds.setValue(params.beds);
          baths = params.baths;
          this.searchQuery.controls.baths.setValue(params.baths);
          min = params.min;
          this.searchQuery.controls.min.setValue(params.min);
          max = params.max;
          this.searchQuery.controls.max.setValue(params.max);
          this.top = params.top;
          this.orderBy = params.orderBy;
          skip = params.skip;

          //set pageIndex
          this.pageIndex=params.skip/params.top;

          //search for listings now
          this.getResults(area, type, beds, baths, min, max, this.top, skip, this.orderBy)
        }
        
      }
    );
    // this.route.params.subscribe(routeParams => {
    //   this.loadUserDetail(routeParams.id);
    // });
  
  }
  loadFilters(filter){
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: {orderBy: filter},
        queryParamsHandling: "merge", // remove to replace all query params by provided
      });
  }
  handlePage(event){
    // console.log(event);
    let skip=event.pageIndex*event.pageSize;
    // this.router.navigateByUrl(this.router.url.replace('top', event.pageSize).replace('skip', skip.toString()));
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: {top: event.pageSize, skip: skip.toString()},
        queryParamsHandling: "merge", // remove to replace all query params by provided
      });
  }
  apply(){

    // console.log(this.searchQuery)
    //route to search page with url params
    this.router.navigate(
      ['/search'],
      { queryParams: 
        { 
          area: this.searchQuery.controls.area.value, 
          type: this.searchQuery.controls.type.value? "'" + this.searchQuery.controls.type.value.join("','") + "'" : '',
          beds: this.searchQuery.controls.beds.value,
          baths: this.searchQuery.controls.baths.value,
          min: this.searchQuery.controls.min.value,
          max: this.searchQuery.controls.max.value,
          top: 50,
          skip: 0,
          orderBy: this.orderBy
        },
      }
    );
  }

}
