<div class="menu-navigation" [class.active]="mobileMenu">
    <div class="menu-row">
       <img class="logo" src="../../../assets/images/DEP-Neg-landscape.png" alt="Double E Properties">
        <div class="menu-items">
            <a class="menu-item" routerLink="/home" routerLinkActive="active">Home</a>
            <a class="menu-item" routerLink="/search" routerLinkActive="active">Search</a>
            <!-- <a class="menu-item" routerLink="/listings" routerLinkActive="active">Our Listings</a> -->
            <a class="menu-item" routerLink="/about" routerLinkActive="active">About</a>
            <a class="menu-item" routerLink="/contact" routerLinkActive="active">Contact</a>
        </div>
        <div class=mobile-menu (click)="toggleMenu()" [class.open]="mobileMenu">
            <fa-icon class="caret" [icon]="faCaret" size="3x"></fa-icon>
        </div> 
    </div>
    
    <div class=mobile-menu-items *ngIf="mobileMenu">
        <div class="m-menu-items">
            <a class="menu-item" routerLink="/home" routerLinkActive="active" (click)="closeMobileMenu()">Home</a>
            <a class="menu-item" routerLink="/search" routerLinkActive="active" (click)="closeMobileMenu()">Search</a>
            <!-- <a class="menu-item" routerLink="/listings" routerLinkActive="active" (click)="closeMobileMenu()">Our Listings</a> -->
            <a class="menu-item" routerLink="/about" routerLinkActive="active" (click)="closeMobileMenu()">About</a>
            <a class="menu-item" routerLink="/contact" routerLinkActive="active" (click)="closeMobileMenu()">Contact</a>
        </div>
    </div>
</div>