import { Component, OnInit } from '@angular/core';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  constructor() { }
  faCaret=faAngleDown;
  mobileMenu=false;

  ngOnInit(): void {
  }
  toggleMenu(){
    this.mobileMenu=!this.mobileMenu;
  }
  closeMobileMenu(){
    this.mobileMenu=false;
  }

}
