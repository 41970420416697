<div class=listing-page *ngIf="this.listingService.isInitialized | async">
    <div class=search><app-search-widget></app-search-widget> </div>   
    <div class=featured>
        <h1>Featured Listings</h1>
        <div class="listings">
            <app-listing *ngFor="let listing of activeListings; index as i;" [listing]=listing>
            </app-listing>
        </div>
       
    </div>
    <div class=sold>
        <h1>Sold Listings</h1>
        <div class="listings">
            <app-listing *ngFor="let listing of soldListings; index as i;" [listing]=listing>
            </app-listing>

        </div>
       
    </div>

   
</div>