import { Component } from '@angular/core';
import { ListingService } from './services/listing.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Esther Easter, Broker';
  isInitialized:Promise<boolean>;
  constructor(public listingService : ListingService) { }
  ngOnInit(){
    // document.querySelector('meta[name="description"]').setAttribute('content', 'Farm and ranch real estate available in Texas');
    //get token no matter what page
    this.listingService.getAuth().subscribe(data => {
      this.listingService.storeToken(data);
      //we have token
      this.isInitialized=Promise.resolve(true);
      //TODO: error if token is not retrieved successfully
    
  });
}
}
