import { Component, OnInit } from '@angular/core';
import { PixelService } from 'ngx-pixel';
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
interface Option {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-search-widget',
  templateUrl: './search-widget.component.html',
  styleUrls: ['./search-widget.component.scss']
})
export class SearchWidgetComponent implements OnInit {

  faCaret=faAngleRight;
  searchQuery:FormGroup;

  constructor(
    private pixel: PixelService, private fb: FormBuilder, private router:Router
  ) {
    this.searchQuery = this.fb.group({
      area: [''],
      type: [''],
      beds: [''],
      baths: [''],
      min: [''],
      max: [''],
    });
   }

  ngOnInit(): void {
  }


  types: Option[] = [
    {value: 'SingleFamilyResidence', viewValue: 'Single Family'},
    {value: 'condos', viewValue: 'Condos'},
    {value: 'multi-family', viewValue: 'Multi Family'},
    {value: 'commercial', viewValue: 'Commercial'},
    {value: 'Land', viewValue: 'Land'},
    {value: 'Residential', viewValue: 'Residential'},
    {value: 'Farm', viewValue: 'Farm'},
    {value: 'income', viewValue: 'Income'},
    {value: 'com-lease', viewValue: 'Com Lease'},
    {value: 'townhome', viewValue: 'Town Home'},
    {value: 'condos', viewValue: 'Condos'},
    {value: 'res-lease', viewValue: 'Res Lease'},
    {value: 'farm-ranch', viewValue: 'Farm & Ranch'},
    {value: 'pending', viewValue: 'Pending'},
    {value: 'contingent', viewValue: 'Contingent'},
  ];
  beds: Option[]= [
    {value: 'any', viewValue: 'Any'},
    {value: '1', viewValue: '1+'},
    {value: '2', viewValue: '2+'},
    {value: '3', viewValue: '3+'},
    {value: '4', viewValue: '4+'},
    {value: '5', viewValue: '5+'},
    {value: '6', viewValue: '6+'},
  ];
  baths: Option[] = [
    {value: '1', viewValue: '1+'},
    {value: '1.5', viewValue: '1.5+'},
    {value: '2', viewValue: '2+'},
    {value: '2.5', viewValue: '2.5+'},
    {value: '3', viewValue: '3+'},
    {value: '4', viewValue: '4+'},
    {value: '5', viewValue: '5+'},
  ];
  prices: Option[] = [
    {value: '25000', viewValue: '$25,000'},
    {value: '50000', viewValue: '$50,000'},
    {value: '75000', viewValue: '$75,000'},
    {value: '100000', viewValue: '$100,000'},
    {value: '125000', viewValue: '$125,000'},
    {value: '150000', viewValue: '$150,000'},
    {value: '175000', viewValue: '$175,000'},
    {value: '200000', viewValue: '$200,000'},
    {value: '250000', viewValue: '$250,000'},
    {value: '300000', viewValue: '$300,000'},
    {value: '350000', viewValue: '$350,000'},
    {value: '400000', viewValue: '$400,000'},
    {value: '450000', viewValue: '$450,000'},
    {value: '500000', viewValue: '$500,000'},
    {value: '550000', viewValue: '$550,000'},
    {value: '600000', viewValue: '$600,000'},
    {value: '650000', viewValue: '$650,000'},
    {value: '700000', viewValue: '$700,000'},
    {value: '800000', viewValue: '$800,000'},
    {value: '900000', viewValue: '$900,000'},
    {value: '100000', viewValue: '$1,000,000 +'}
  ];
  formatLabel(value: number) {
    if (value >= 1000 && value <1000000) {
      return Math.round(value / 1000) + 'k';
    }
    else if (value >= 1000000) {
      return value / 1000000 + 'mil';
    }

    return value;
  }
  search(){
    this.pixel.track('Search', {
      contents: [{id: 'test'}],  // Search info
    });
    console.log(this.searchQuery)
    //route to search page with url params
    this.router.navigate(
      ['/search'],
      { queryParams: 
        { 
          area: this.searchQuery.controls.area.value, 
          type: this.searchQuery.controls.type.value? "'" + this.searchQuery.controls.type.value.join("','") + "'" : '',
          beds: this.searchQuery.controls.beds.value,
          baths: this.searchQuery.controls.baths.value,
          min: this.searchQuery.controls.min.value,
          max: this.searchQuery.controls.max.value,
          top: 50,
          skip: 0,
          orderBy: 'OnMarketTimestamp desc'
        },
      }
    );
  
  }
}
