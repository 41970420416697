import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Cookie } from 'ng2-cookies/ng2-cookies';
export interface listing{
  name:string;
  id:number;
  city?:string;
  address?:string;
  county?:string;
  acreage?:number;
  price?:number;
  status?:string;
  description?:string;
  featuredImage?:string;
  gallery?:Array<string>;
  label?:string;
}
export interface query {
  name?:Array<string>;
  id?:number;
  city?:Array<string>;
  address?:Array<string>;
  county?:Array<string>;
  acreage?:Array<number>;
  price?:Array<number>;
  status?:Array<string>;
  label?:Array<string>;
}
@Injectable({
  providedIn: 'root'
})

export class ListingService {

  clientID="81d1ce1a_3b80_4f9e_a260_febcf5d98a64";
  secret="9b7f59b3e4804e96a6b2820fd7fa78c1";
  public isInitialized: Promise<boolean>;

  token;
  listings:Array<any>=[];

  constructor(private http: HttpClient) { }
  //TODO: Quota/error handling
  getActiveListings():Array<listing>{
      return this.listings.filter(x => x.StandardStatus == 'Active')    
  }
  getSoldListings():Array<listing>{
    return this.listings.filter(x => x.status == "Sold")
  }
  getListing(id:number, expandMedia=false){
    let mediaQuery="";
    let heads=new HttpHeaders()
      .set('Authorization', 'Bearer '+this.token)
      .set('Accept', 'application/json')
      .set('Access-Control-Allow-Origin', '*');
    let options = {
      headers: heads,

    };
    if(expandMedia){
      mediaQuery="&$expand=Media($orderby=Order)"
    }
    return this.http.get("https://api-trestle.corelogic.com/trestle/odata/Property?$filter=ListingKey eq '"+id+"'"+mediaQuery, options);
  }
  search(criteria:query){
    return this.listings.filter(function(obj) {
      return Object.keys(criteria).every(function(key) {
        return (Array.isArray(criteria[key]) &&
          (criteria[key].some(function(criteria) {
            return (typeof obj[key] === 'string' && obj[key].indexOf(criteria) !== -1)
          })) || criteria[key].length === 0);
      });
    });
  }
  getAuth(){
    //check if cookie is set first
    // if(Cookie.get('authToken')!=null){
    //   //make sure our token var is set
    //   if(this.token==null){
    //     this.token=Cookie.get('authToken')
    //   }
    //   return;
    // }
    //TODO: Test if token is expired

    //if not set, get a new one
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };
    let url="https://api-trestle.corelogic.com/trestle/oidc/connect/token"
    
   return this.http.post(url,"scope=api&client_secret="+this.secret+"&grant_type=client_credentials&client_id="+this.clientID+"&$expand=Media($orderby=Order)", options)
    
    
  }
  storeToken(data){
    this.token=data['access_token'];
    // console.log(this.token)
      //setter for token
      if(data!=null && data!=undefined){
        Cookie.set('authToken', data['access_token']);
      }
      
      //getter for token
      // let token = Cookie.get('authToken');
      //delete token cookie
      // Cookie.delete('authToken');
  }
  getData(){
    let heads=new HttpHeaders()
      .set('Authorization', 'Bearer '+this.token)
      .set('Accept', 'application/json')
      .set('Access-Control-Allow-Origin', '*');
    let options = {
      headers: heads,

    };
    // let url="https://api-trestle.corelogic.com/trestle/odata/[model name] ?$filter=[ query]";
    this.http.get("https://api-trestle.corelogic.com/trestle/odata/Property", options).subscribe(data=> {
      this.listings=data["value"];
      this.isInitialized=Promise.resolve(true);
    })

    // return this.dataPromise;
    // return this.http.get("https://api-trestle.corelogic.com/trestle/odata/Property", options)
  }
  getMedia(id, top?){
    let topQuery
    let heads=new HttpHeaders()
      .set('Authorization', 'Bearer '+this.token)
      .set('Accept', 'application/json')
      .set('Access-Control-Allow-Origin', '*');
    let options = {
      headers: heads,

    };
    if(top){
      topQuery="&$top="+top;
    }
    else{
      topQuery="&$top=1000";
    }
    // let url="https://api-trestle.corelogic.com/trestle/odata/[model name] ?$filter=[ query]";
    return this.http.get("https://api-trestle.corelogic.com/trestle/odata/Media?$filter=ResourceRecordKey eq '"+id+"'&$orderby=Order"+topQuery, options);
  }
  getRecents(top=50){
    let heads=new HttpHeaders()
      .set('Authorization', 'Bearer '+this.token)
      .set('Accept', 'application/json')
      .set('Access-Control-Allow-Origin', '*');
    let options = {
      headers: heads,

    };
    let topQuery="&$top="+top;
    let today = new Date();
    let daysAgo=today.setDate(today.getDate() - 2);
    const dateJSONToEDM = jsonDate => {
      const content = /\d+/.exec(String(jsonDate));
      const timestamp = content ? Number(content[0]) : 0;
      const date = new Date(timestamp);
      const string = date.toISOString();
      return string;
    };
    return this.http.get("https://api-trestle.corelogic.com/trestle/odata/Property?$filter=OnMarketTimestamp gt "+dateJSONToEDM(daysAgo)+" and LotSizeAcres ge 2 and PropertyType eq 'Residential'&$orderby=ListPrice desc&$expand=Media($orderby=Order)&$count=true"+topQuery, options);

  }
  getLand(top=50){
    let heads=new HttpHeaders()
      .set('Authorization', 'Bearer '+this.token)
      .set('Accept', 'application/json')
      .set('Access-Control-Allow-Origin', '*');
    let options = {
      headers: heads,

    };
    let topQuery="&$top="+top;
    return this.http.get("https://api-trestle.corelogic.com/trestle/odata/Property?$filter=PropertyType eq 'Land'&$orderby=ListPrice desc&$expand=Media($orderby=Order)&$count=true"+topQuery, options);

  }
  searchQuery(area?, type?, beds?, baths?, min?, max?, top=50, skip=0, orderBy='OnMarketTimestamp desc'){
    let heads=new HttpHeaders()
      .set('Authorization', 'Bearer '+this.token)
      .set('Accept', 'application/json')
      .set('Access-Control-Allow-Origin', '*');
    let options = {
      headers: heads,

    };
    //build query from params
    let query;
    let priceRange
    let areaQuery;
    let bedsQuery;
    let bathsQuery;
    let activeQuery;
    let typeQuery;
    let topQuery="&$top="+top;
    let skipQuery="&$skip="+skip;
    let orderByQuery="&$orderby="+orderBy;
    //area filter: city, area, zip, county, mls
    if(area){
      //captalize area in case our users don't
      area=area.charAt(0).toUpperCase() + area.slice(1)
      areaQuery="(City eq '"+area+"' or CountyOrParish eq '"+area+"' or City eq '"+area+"' or PostalCode eq '"+area+"' or ListingKey eq '"+area+"')";
    }
    if(type){
      //type shoud be string list of types
      typeQuery="(PropertyType in ("+type+") or PropertySubTypeAdditional in ("+type+"))";
    }
    if(beds){
      bedsQuery="(BedroomsTotal ge "+beds+")";
    }
    if(baths){
      //TODO: add bathmath for half bath
      let bathsNum=parseFloat(baths);
      let halfBaths;
      if(bathsNum % 1 != 0){
        //then it has a half
        halfBaths=1;
        //set baths to floor to round down for whole baths
        bathsNum=Math.floor(bathsNum);
      }
      else{
        //no half baths, a round number to start
        halfBaths=0;
      }
      //otherwise it's already whole
      bathsQuery="(BathroomsFull ge "+bathsNum.toString()+" and BathroomsHalf ge "+halfBaths+")"
    }
    //price filter
    if(min && max){
      //we have a range
      priceRange="(ListPrice ge "+min+" and ListPrice le "+max+")";
    }
    else if(min){
      priceRange="(ListPrice ge "+min+")";
    }
    else if(max){
      priceRange="(ListPrice le "+max+")";
    }
    //add that it should be an active listing
    activeQuery="(StandardStatus eq 'Active')"

    //all queries are made, concatonate them
    query = [areaQuery, bedsQuery, bathsQuery, priceRange, activeQuery, typeQuery].filter(Boolean).join(" and ");
    // console.log(query);
    return this.http.get("https://api-trestle.corelogic.com/trestle/odata/Property?$filter="+encodeURI(query)+topQuery+skipQuery+orderByQuery+"&$expand=Media($select=MediaURL;$top=1;$orderby=Order)&$count=true", options);
  }
  getDOM(date){
    let DOM = new Date(date);
    let today = new Date();
    let Difference_In_Time = today.getTime() - DOM.getTime();
    return Math.ceil(Difference_In_Time / (1000 * 3600 * 24));
  }
}
