<div class="listing-details" *ngIf="dataPromise | async; else loading">
    
    <div class="image">
        <img class="featured-image" [src]="src" />
        <div class="quick-look">
          <div class="title">{{listing.UnparsedAddress}}</div>
        <div class="price-change" [ngClass]="{'change-less': !isPriceMore(listing.ListPrice, listing.OriginalListPrice), 'change-more': isPriceMore(listing.ListPrice, listing.OriginalListPrice)}" *ngIf="listing.MajorChangeType == 'PriceChange'">Price {{isPriceMore(listing.ListPrice, listing.OriginalListPrice) ?  'Increase' : 'Drop'}}</div>
        </div>
        
    </div>
    <div class=body>
        <div class=description>
          <div class="res-stats">
            <span class=price>{{listing.ListPrice | currency:'USD':'symbol':'3.0'}}<span class="old-price" *ngIf="listing.MajorChangeType == 'PriceChange'">{{listing.PreviousListPrice | currency:'USD':'symbol':'3.0'}}</span></span>
            <span *ngIf="listing.BedroomsTotal"><fa-icon class="list-icon" [icon]="bed" size="sm"></fa-icon>{{listing.BedroomsTotal}} bed </span>
            <span *ngIf="listing.BathroomsFull"><fa-icon class="list-icon" [icon]="bath" size="sm"></fa-icon>{{getBaths(listing.BathroomsFull, listing.BathroomsHalf)}} bath </span>
            <span *ngIf="listing.PropertyType!='Residential'"><fa-icon class="list-icon" [icon]="ruler" size="sm"></fa-icon>{{listing.LotSizeArea}} {{listing.LotSizeUnits}}</span>
            <span *ngIf="listing.PropertyType=='Residential'"><fa-icon class="list-icon" [icon]="ruler" size="sm"></fa-icon>{{listing.BuildingAreaTotal}} sqft</span>

            <!-- TODO: replace with sqft -->
          </div>
          <div class="property-info">
            <div class=title><h2>Property Information</h2><div class=line></div></div>
            <div class=button-row><button class="open-close" (click)="toggleExpand()">{{this.expanded ? 'Collapse All' : 'Expand All'}}</button></div>
           <mat-accordion multi='true' #firstAccordion="matAccordion">
            <mat-expansion-panel *ngIf="listing.PublicRemarks" expanded="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                    <fa-icon class="acc-icon" [icon]="house" size="md"></fa-icon>Property Description
                </mat-panel-title>
                <!-- <mat-panel-description>
                  This is a summary of the content
                </mat-panel-description> -->
              </mat-expansion-panel-header>
              <p>{{listing.PublicRemarks}}</p>
            </mat-expansion-panel>
            <mat-expansion-panel expanded="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <fa-icon class="acc-icon" [icon]="table" size="md"></fa-icon>Property Details
                </mat-panel-title>
                <!-- <mat-panel-description>
                  This is a summary of the content
                </mat-panel-description> -->
              </mat-expansion-panel-header>
              <table class="details-table">
                  <thead>
                      <tr><th></th><th></th></tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="listing.BathroomsFull!=null"><td>Baths (Full)</td><td>{{listing.BathroomsFull}}</td></tr>
                    <tr *ngIf="listing.BathroomsHalf!=null"><td>Baths (Half)</td><td>{{listing.BathroomsHalf}}</td></tr>
                    <tr *ngIf="listing.BedroomsTotal!=null"><td>Beds</td><td>{{listing.BedroomsTotal}}</td></tr>
                    <tr *ngIf="listing.Cooling!=null"><td>Cooling</td><td>{{getFeature(listing.Cooling)}}</td></tr>
                    <tr *ngIf="listing.Heating!=null"><td>Heating</td><td>{{getFeature(listing.Heating)}}</td></tr>
                      <tr *ngIf="listing.Directions!=null"><td>Directions</td><td>{{listing.Directions}}</td></tr>
                      <!-- <tr *ngIf="listing.Fencing!=null"><td>Fencing</td><td>{{getFeature(listing.Fencing)}}</td></tr> -->
                      <tr *ngIf="listing.Fireplace!=null"><td>Fireplace</td><td>{{listing.Fireplace ? 'Yes' : 'No'}}</td></tr>
                      <tr *ngIf="listing.FoundationDetails!=null"><td>Foundation</td><td>{{getFeature(listing.FoundationDetails)}}</td></tr>
                      <tr *ngIf="listing.CarportYN!=null"><td>Carport</td><td>{{listing.CarportYN ? 'Yes' : 'No'}}</td></tr>
                      <tr *ngIf="listing.CarportSpaces!=null"><td>Carport Spaces</td><td>{{listing.CarportSpaces}}</td></tr>
                      <tr *ngIf="listing.GarageYN!=null"><td>Garage</td><td>{{listing.GarageYN ? 'Yes' : 'No'}}</td></tr>
                      <tr *ngIf="listing.GarageSpaces!=null"><td>Garage Spaces</td><td>{{listing.GarageSpaces}}</td></tr>
                      <tr *ngIf="listing.AttachedGarage!=null"><td>Attached Garage</td><td>{{listing.AttachedGarage}}</td></tr>
                      <tr *ngIf="listing.ElementarySchoolDistrict!=null"><td>Elementary School District</td><td>{{listing.ElementarySchoolDistrict}}</td></tr>
                      <tr *ngIf="listing.HighSchoolDistrict!=null"><td>High School District</td><td>{{listing.HighSchoolDistrict}}</td></tr>
                      <!-- <tr *ngIf="listing.AssociationYN!=null"><td>HOA</td><td>{{listing.AssociationYN ? 'Yes' : 'No'}}</td></tr>
                      <tr *ngIf="listing.AssociationFee!=null"><td>HOA Fee</td><td>{{listing.AssociationFee | currency}} {{listing.AssociationFeeFrequency}}</td></tr> -->

                      <tr *ngIf="listing.LandLeaseYN!=null"><td>Land Lease</td><td>{{listing.LandLeaseYN ? 'Yes' : 'No'}}</td></tr>
                      <tr *ngIf="listing.LandLeaseExpirationDate!=null"><td>Land Lease Expiration</td><td>{{listing.LandLeaseExpirationDate}}</td></tr>
                      <tr *ngIf="listing.TaxLegalDescription!=null"><td>Legal</td><td>{{listing.TaxLegalDescription}}</td></tr>
                      <tr *ngIf="listing.LivingArea!=null"><td>Living Area</td><td>{{listing.LivingArea}}</td></tr>
                      <tr *ngIf="listing.LivingAreaSource!=null"><td>Living Area Source</td><td>{{listing.LivingAreaSource}}</td></tr>
                      <tr *ngIf="listing.LotSizeDimensions!=null"><td>Lot Size Dimensions</td><td>{{listing.LotSizeDimensions}}</td></tr>
                      <tr *ngIf="listing.Possesion!=null"><td>Possesion</td><td>{{getFeature(listing.Possesion)}}</td></tr>
                      <tr *ngIf="listing.Stories!=null"><td>Stories</td><td>{{listing.Stories}}</td></tr>
                      <tr *ngIf="listing.RoadFrontageType!=null"><td>Road Frontage Type</td><td>{{listing.RoadFrontageType}}</td></tr>
                      <!-- <tr *ngIf="listing.Roof!=null"><td>Roof</td><td>{{listing.Roof }}</td></tr> -->
                      <tr *ngIf="listing.SubdivisionName!=null"><td>Subdivision Name</td><td>{{listing.SubdivisionName}}</td></tr>
                      <!-- <tr *ngIf="listing.TaxAnnualAmount!=null"><td>Tax Annual Amount</td><td>{{listing.TaxAnnualAmount | currency}}</td></tr> -->
                      <tr *ngIf="listing.TaxYear!=null"><td>Tax Year</td><td>{{listing.TaxYear}}</td></tr>
                      <tr *ngIf="listing.Topography!=null"><td>Topography</td><td>{{listing.Topography}}</td></tr>
                      <tr *ngIf="listing.ViewYN!=null"><td>View</td><td>{{listing.ViewYN ? 'Yes' : 'No'}}</td></tr>
                      <tr *ngIf="listing.WaterfrontYN!=null"><td>Waterfront</td><td>{{listing.WaterfrontYN ? 'Yes' : 'No'}}</td></tr>

                  </tbody>
              </table>
            </mat-expansion-panel>
            <mat-expansion-panel expanded="true">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <fa-icon class="acc-icon" [icon]="clipboard" size="md"></fa-icon>Property Features
                  </mat-panel-title>
                  <!-- <mat-panel-description>
                    This is a summary of the content
                  </mat-panel-description> -->
                </mat-expansion-panel-header>
                <div *ngIf="listing.InteriorFeatures">
                    <div class="section-header">Interior Features</div>
                    <ul>
                        <li *ngFor="let feature of getArray(listing.InteriorFeatures)">{{getFeature(feature)}}</li>
                    </ul>
                </div>
                <div *ngIf="listing.Flooring">
                  <p class="section-subheader">Flooring</p>
                  <ul >
                    <li *ngFor="let feature of getArray(listing.Flooring)">{{getFeature(feature)}}</li>
                  </ul>
                </div>
                <div *ngIf="listing.FireplaceFeatures">
                  <div class="section-subheader">Fireplace</div>
                  <ul>
                      <li *ngFor="let feature of getArray(listing.FireplaceFeatures)">{{getFeature(feature)}}</li>
                  </ul>
              </div>
                <div *ngIf="listing.ExteriorFeatures">
                    <div class="section-header">Exterior Features</div>
                    <ul>
                        <li *ngFor="let feature of getArray(listing.ExteriorFeatures)">{{getFeature(feature)}}</li>
                    </ul>
 
                </div>
                <div *ngIf="listing.Roof">
                  <p class="section-subheader">Roof</p>
                  <ul >
                      <li *ngFor="let feature of getArray(listing.Roof)">{{getFeature(feature)}}</li>
                  </ul>
                </div>
                  
                <div *ngIf="listing.Fencing && listing.Fencing!='None'">
                  <p class="section-subheader">Fencing</p>
                  <ul >
                    <li *ngFor="let feature of getArray(listing.Fencing)">{{getFeature(feature)}}</li>
                  </ul>
                </div>
                <div *ngIf="listing.Appliances">
                  <div class="section-header">Appliances</div>
                  <ul>
                      <li *ngFor="let feature of getArray(listing.Appliances)">{{getFeature(feature)}}</li>
                  </ul>
              </div>
                <div *ngIf="listing.MainLevelBedrooms || listing.MainLevelBathrooms">
                    <div class="section-header">Beds & Baths</div>
                    <p>{{listing.MainLevelBedrooms}}</p>
                    <p>{{listing.MainLevelBathrooms}}</p>
                </div>
                <div *ngIf="listing.Utilities">
                    <div class="section-header">Utilities</div>
                    <ul>
                        <li *ngFor="let feature of getArray(listing.Utilities)">{{getFeature(feature)}}</li>
                    </ul>
                </div>
                <div *ngIf="listing.PoolFeatures!='None' && listing.PoolFeatures || listing.SpaFeatures && listing.SpaFeatures!='None'">
                  <div class="section-header">Pool & Spa Features</div>
                  <ul *ngIf="listing.PoolFeatures">
                      <li *ngFor="let feature of getArray(listing.PoolFeatures)">{{getFeature(feature)}}</li>
                  </ul>
                  <ul *ngIf="listing.SpaFeatures">
                    <li *ngFor="let feature of getArray(listing.SpaFeatures)">{{getFeature(feature)}}</li>
                </ul>
              </div>
              <div *ngIf="listing.GreenEnergyEfficient">
                <div class="section-header">Energy Features</div>
                <ul>
                    <li *ngFor="let feature of getArray(listing.GreenEnergyEfficient)">{{getFeature(feature)}}</li>
                </ul>
            </div>
            <div *ngIf="listing.ConstructionMaterials">
              <div class="section-header">Construction</div>
              <ul>
                  <li *ngFor="let feature of getArray(listing.ConstructionMaterials)">{{getFeature(feature)}}</li>
              </ul>
          </div>
          
                
                
              </mat-expansion-panel>
            
              
            
          </mat-accordion>
        </div>
        </div>
        <div class=stats>
            <!-- <div class=details>Property Stats</div> -->
            <div class="stat"><span>Status</span><span class="stat-value"><div class=status [ngClass]="{'active': listing.StandardStatus=='Active', 'pending': listing.StandardStatus=='Pending'}"> </div>{{listing.StandardStatus}}</span></div>

            <div class="stat"><span>Address</span><span class="stat-value">{{listing.UnparsedAddress}} {{listing.City}}, {{listing.StateOrProvince}} {{listing.PostalCode}}</span></div>
            <div class="stat" *ngIf="listing.SubdivisionName"><span>Neighborhood</span><span class="stat-value">{{listing.SubdivisionName}}</span></div>
            <!-- <div class="stat"><span>City</span><span class="stat-value">{{listing.City}}</span></div>
            <div class="stat"><span>Postal Code</span><span class="stat-value">{{listing.PostalCode}}</span></div> -->
            <div class="stat"><span>County</span><span class="stat-value">{{listing.CountyOrParish}}</span></div>
            <div class="stat" *ngIf="listing.LotSizeAcres"><span>Acreage</span><span class="stat-value">{{listing.LotSizeAcres}} +/-</span></div>
            <div class="stat" *ngIf="listing.PropertyType=='Residential'"><span>Price per sqft</span><span class="stat-value">{{listing.ListPrice/listing.BuildingAreaTotal | currency}}</span></div>
            <div class="stat" *ngIf="listing.PropertyType!='Residential'"><span>Price per acre</span><span class="stat-value">{{listing.ListPrice/listing.LotSizeAcres | currency}}</span></div>

            <div class="stat"><span>HOA</span><span class="stat-value" *ngIf="listing.AssociationYN">{{listing.AssociationFee | currency}} {{listing.AssociationFeeFrequency}}</span><span class="stat-value" *ngIf="!listing.AssociationYN">None</span></div>
            <div class="stat" *ngIf="listing.TaxAnnualAmount"><span>Annual Taxes</span><span class="stat-value">{{listing.TaxAnnualAmount | currency}}</span></div>
            
            <div class="stat" *ngIf="listing.YearBuilt"><span>Year Built</span><span class="stat-value">{{listing.YearBuilt}}</span></div>

            <div class="stat" *ngIf="listing.PropertyType"><span>Property Type</span><span class="stat-value">{{listing.PropertyType}}</span></div>

            <div class="stat"><span>Days on Market</span><span class="stat-value">{{getDOM()}}</span></div>
            <!-- <button *ngIf="listing.status!='Sold'" class="contact" routerLink="/contact">More Information</button> -->
            
        </div>
        <div class=gallery *ngIf="items!=null">
          <div class=title><h2>Gallery</h2><div class=line></div></div>

          <div class="gallerize-example">

              <div class="container">
                <div class="img-hover-zoom" *ngFor="let item of items; index as i">
                  <img  
                   [lightbox]="i"
                   [src]="item.data.thumb">
                </div>
              </div>
            
      </div>
  </div>
        <div class=maps>
          <div class=title><h2>Location</h2><div class=line></div></div>
          <div class=map>
            <!-- <agm-map (mapReady)="onMapReady($event)" [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" [scrollwheel]="false" [styles]="style">
            <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="false"
              ></agm-marker>
          </agm-map> -->
          <div #map style="width:100%;height:400px"></div>
          </div>
          
        </div>
    </div>

    
    

    <p class="provided">Listing provided by {{listing.ListOfficeName}}</p>          
</div>
<ng-template #loading><div class=loading-screen><mat-spinner></mat-spinner></div></ng-template>
