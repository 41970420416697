import { Component, OnInit, Inject } from '@angular/core';
import { faTwitter, faYoutube, faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
  title: string;
  image: string;
}
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {


  twitter=faTwitter;
  youtube=faYoutube;
  facebook=faFacebook;
  linkedin=faLinkedin;

  constructor(public disclosure: MatDialog) {}


  ngOnInit(): void {
  }
  openDisclosure(): void {
    const dialogRef = this.disclosure.open(DialogDisclosure, {
      height: '90vh',
      data: {title: "Consumer Protection Notice", image: "../../../assets/images/ConsumerProtectionNotice.jpg"},
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

openInfo(): void {
  const dialogRef = this.disclosure.open(DialogDisclosure, {
    height: '90vh',
    data: {title: "Information About Brokerage Services", image: "../../../assets/images/IABS-DEP.jpg"},
  });

  dialogRef.afterClosed().subscribe(result => {
  });
}

}
@Component({
  selector: 'dialog-overview-example-dialog',
  template: `<h2 mat-dialog-title>{{data.title}}</h2>
  <div mat-dialog-content><img src={{data.image}}></div>`,
  styleUrls: ['./dialog-styles.component.scss']
})
export class DialogDisclosure {
  constructor(
    public dialogRef: MatDialogRef<DialogDisclosure>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
