<div class="search-widget">
    <div class="search-desc">Search Active Listings</div>
    <form class="search" [formGroup]="searchQuery">
        <mat-form-field appearance="outline">
            <mat-label>Area</mat-label>
            <input formControlName="area" matInput placeholder="City, Area, Zip, MLS #, County">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Type</mat-label>
            <mat-select multiple formControlName="type">
            <mat-option *ngFor="let type of types" [value]="type.value">
                {{type.viewValue}}
            </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="input-small" appearance="outline">
            <mat-label>Beds</mat-label>
            <mat-select formControlName="beds">
            <mat-option *ngFor="let bed of beds" [value]="bed.value">
                {{bed.viewValue}}
            </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="input-small" appearance="outline">
            <mat-label>Baths</mat-label>
            <mat-select formControlName="baths">
            <mat-option *ngFor="let bath of baths" [value]="bath.value">
                {{bath.viewValue}}
            </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="input-small" appearance="outline">
            <mat-label>Min</mat-label>
            <mat-select formControlName="min">
            <mat-option *ngFor="let price of prices" [value]="price.value">
                {{price.viewValue}}
            </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="input-small" appearance="outline">
            <mat-label>Max</mat-label>
            <mat-select formControlName="max">
            <mat-option *ngFor="let price of prices" [value]="price.value">
                {{price.viewValue}}
            </mat-option>
            </mat-select>
        </mat-form-field>
        
           

        <button mat-raised-button class="search-button" color="primary" (click)="search()">Search</button>
    </form>
    <a class="detailed-search-link" (click)="search()">Detailed Search<fa-icon class="search-caret" [icon]="faCaret" size="sm"></fa-icon></a>
</div>