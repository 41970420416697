<div class="search-page" *ngIf="dataPromise | async; else loading">
    <mat-sidenav-container class="example-container" hasBackdrop="true" >
        <mat-sidenav #drawer mode="push" fixedInViewport="true" [style.paddingTop.px]="125">
            <div class=filters>
                <h3>Sort & Filter</h3>
                <form class="search" [formGroup]="searchQuery">
                    <mat-form-field appearance="outline">
                        <mat-label>Area</mat-label>
                        <input formControlName="area" matInput placeholder="City, Area, Zip, MLS #, County">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Type</mat-label>
                        <mat-select multiple formControlName="type">
                        <mat-option *ngFor="let type of types" [value]="type.value">
                            {{type.viewValue}}
                        </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="input-small" appearance="outline">
                        <mat-label>Beds</mat-label>
                        <mat-select formControlName="beds">
                        <mat-option *ngFor="let bed of beds" [value]="bed.value">
                            {{bed.viewValue}}
                        </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="input-small" appearance="outline">
                        <mat-label>Baths</mat-label>
                        <mat-select formControlName="baths">
                        <mat-option *ngFor="let bath of baths" [value]="bath.value">
                            {{bath.viewValue}}
                        </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="input-small" appearance="outline">
                        <mat-label>Min</mat-label>
                        <mat-select formControlName="min">
                        <mat-option *ngFor="let price of prices" [value]="price.value">
                            {{price.viewValue}}
                        </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="input-small" appearance="outline">
                        <mat-label>Max</mat-label>
                        <mat-select formControlName="max">
                        <mat-option *ngFor="let price of prices" [value]="price.value">
                            {{price.viewValue}}
                        </mat-option>
                        </mat-select>
                    </mat-form-field>
                    
                       
            
                    <button mat-raised-button class="search-button" color="primary" (click)="apply()">Apply Filters</button>
                </form>
            </div>
            
        </mat-sidenav>
        <mat-sidenav-content>
          <!-- <mat-form-field appearance="fill">
            <mat-label>Sidenav mode</mat-label>
            <mat-select #mode value="side">
              <mat-option value="side">Side</mat-option>
              <mat-option value="over">Over</mat-option>
              <mat-option value="push">Push</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Has backdrop</mat-label>
            <mat-select #hasBackdrop>
              <mat-option>Unset</mat-option>
              <mat-option [value]="true">True</mat-option>
              <mat-option [value]="false">False</mat-option>
            </mat-select>
          </mat-form-field> -->
          <!-- <button class="drawer-toggle" (click)="drawer.toggle()"><fa-icon class="search-caret" [icon]="angle" size="md"></fa-icon></button> -->
        
      

    <div class=featured>
        <div class=title-row>
           <div class=title>{{queryCount}} Results</div> 
            <div class=forms>
            <mat-form-field appearance="fill">
                <mat-label>Sort by</mat-label>
                <mat-select #filter disableOptionCentering="true" [value]="orderBy" (selectionChange)="loadFilters(filter.value)">
                    <mat-option value="OnMarketTimestamp desc">Recent</mat-option>
                    <mat-option value="LotSizeAcres desc">Acreage</mat-option>
                    <mat-option value="BuildingAreaTotal desc">Square Footage</mat-option>
                    <mat-option value="ListPrice asc">Price (Low to High)</mat-option>
                    <mat-option value="ListPrice desc">Price (High to Low)</mat-option>
                </mat-select>
            </mat-form-field>
            <button mat-raised-button class="filter-button" color="primary" (click)="drawer.toggle()">Update Filters</button>    
           </div>
        </div>
        
        <div class="listings">
            <app-listing *ngFor="let listing of listings; index as i;" [listing]=listing>
            </app-listing>
            <p *ngIf="listings.length==0">There were no results for your desired search. Please refine your criteria and try again.</p>
        </div>
        <mat-paginator [length]="queryCount"
        [pageSize]="this.top"
        [pageSizeOptions]="[50, 75, 100]"
        [pageIndex]="pageIndex"
        (page)="handlePage($event)"
        aria-label="Select page">
</mat-paginator>
    </div>
</mat-sidenav-content>
</mat-sidenav-container>
</div>
<ng-template #loading><div class=loading-screen><mat-spinner></mat-spinner></div></ng-template>

