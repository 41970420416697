<div class=listing *ngIf="this.listing['Media'] && this.listing['Media'][0]['MediaURL']!=null">
    <div class=listing-image>
        <img class="featured-image" [src]="this.listing['Media'][0]['MediaURL']" />
        <div class="overlay">
            <a class="overlay-text" [routerLink]="['/listing', listing.ListingKey]">View Listing</a>
        </div>
    </div>
    <div class="listing-label" *ngIf="listing.MajorChangeType=='NewListing' && getDOM() < 3 || listing.MajorChangeType!='NewListing'"[ngClass]="{'sold' : listing.StandardStatus=='Sold'}">{{getChange(listing.MajorChangeType)}}</div>
    <div class="listing-header">
        <div class="listing-name">
            {{listing.City}} - {{listing.PostalCode}}
        </div>
        <div class="listing-price" *ngIf="listing.status!='Sold'">
            {{listing.ListPrice | currency:'USD':'symbol':'3.0'}}
        </div>
    </div>
    <div class=listing-body>
        <div class="listing-addr">
            <fa-icon [icon]="faMap"></fa-icon>
        {{listing.UnparsedAddress}}
        </div>
        <div class=listing-details *ngIf="listing.PropertyType!='Residential'">
            <div class=listing-item><div>Acreage</div><div>{{listing.LotSizeAcres}} +/-</div></div>
            <div class=listing-item><div>County</div><div>{{listing.CountyOrParish}}</div></div>
            <div class=listing-item><div>Status</div><div>{{listing.StandardStatus}}</div></div>
        </div> 
        <div class=listing-details *ngIf="listing.PropertyType=='Residential'">
            <div class=listing-item ><div class=res-stats><span *ngIf="listing.BathroomsFull"><fa-icon class="list-icon" [icon]="bed" size="sm"></fa-icon>{{listing.BedroomsTotal}} bed </span><span *ngIf="listing.BathroomsFull"><fa-icon class="list-icon" [icon]="bath" size="sm"></fa-icon>{{getBaths(listing.BathroomsFull, listing.BathroomsHalf)}} bath </span><span><fa-icon class="list-icon" [icon]="sign" size="sm"></fa-icon>{{listing.BuildingAreaTotal}} sqft</span></div></div>
            <div class=listing-item><div>Acreage</div><div>{{listing.LotSizeAcres}} +/-</div></div>
            <div class=listing-item><div>Status</div><div>{{listing.StandardStatus}}</div></div>
        </div> 
    </div>
    
        </div>  