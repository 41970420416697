import { Component, OnInit, Input } from '@angular/core';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faBed, faBath, faSign } from '@fortawesome/free-solid-svg-icons'
import { ListingService } from 'src/app/services/listing.service';

@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss']
})
export class ListingComponent implements OnInit {

  constructor(public listingService : ListingService) { }
  @Input() listing;
  src;
  bed=faBed;
  bath=faBath;
  sign=faSign;

  getChange(change){
    return change!=null? change.replace(/([a-z])([A-Z])/g, '$1 $2') : null;
  }

  getBaths(full, half){
    if(half==null){
      half=0;
    }
    if(full==null){
      full=0;
    }
    return parseInt(full)+(parseInt(half)*.5);
  }
  faMap = faMapMarkerAlt;
  ngOnInit(): void {
  }
  getDOM(){
    return this.listingService.getDOM(this.listing.OnMarketTimestamp);
  }

}
