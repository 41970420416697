import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { AgmCoreModule } from '@agm/core';
import { MenuComponent } from './components/menu/menu.component';
import { SliderComponent } from './components/slider/slider.component';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { SearchWidgetComponent } from './components/search-widget/search-widget.component';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSliderModule} from '@angular/material/slider'
import { MatButtonModule } from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ListingsComponent } from './components/listings/listings.component';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { FooterComponent } from './components/footer/footer.component';
import { ListingComponent } from './components/listings/listing/listing.component';
import { ListingService } from './services/listing.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ListingDetailsComponent } from './components/listings/listing-details/listing-details.component'
import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule } from '@ngx-gallery/lightbox';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { PixelModule } from 'ngx-pixel';
import { HttpClientModule } from '@angular/common/http';
import { SearchComponent } from './components/search/search.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatDialogModule} from '@angular/material/dialog';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MenuComponent,
    SliderComponent,
    SearchWidgetComponent,
    ListingsComponent,
    AboutComponent,
    ContactComponent,
    FooterComponent,
    ListingComponent,
    ListingDetailsComponent,
    SearchComponent,
  ],
  imports: [
    BrowserModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyDdzByjPbkOzbmlexvgaJgHJZNFN22BpVo',
    //   libraries: ['places', 'localContext', 'drawing']
    // }),
    AppRoutingModule,
    BrowserAnimationsModule,
    MatCarouselModule.forRoot(),
    MatSelectModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatSliderModule,
    FontAwesomeModule,
    GalleryModule,
    LightboxModule,
    FormsModule,
    ReactiveFormsModule,
    PixelModule.forRoot({ enabled: true, pixelId: '393492622206038' }),
    HttpClientModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatDialogModule,
  ],
  providers: [ListingService],
  bootstrap: [AppComponent]
})
export class AppModule { }
