<div height="700px">
    <mat-carousel
        #slider
      timings="450ms ease-in"
      [autoplay]="true"
      [maintainAspectRatio]="false"
      interval="8000"
      color="primary"
      maxWidth="auto"
      proportion="25"
      slides="5"
      slideHeight="700px"
      [loop]="true"
      [hideArrows]="true"
      [hideIndicators]="false"
      [useKeyboard]="true"
      [useMouseWheel]="false"
      orientation="ltr"
    >
      <mat-carousel-slide
        #matCarouselSlide
        *ngFor="let slide of slides; let i = index"
        [image]="slide.image"
        overlayColor="#00000040"
        [hideOverlay]="false"
      ></mat-carousel-slide>
    </mat-carousel>
    <div class="search"><app-search-widget id="widget"></app-search-widget></div>
    
  </div>
      