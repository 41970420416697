import { Component, OnInit, ViewChild, NgZone, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ListingService } from 'src/app/services/listing.service';
import { Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize } from '@ngx-gallery/core';
import { map } from 'rxjs/operators';
import { faHome, faClipboardList, faTable, faBed, faBath, faRuler, faDollarSign } from '@fortawesome/free-solid-svg-icons'

import { MatAccordion } from '@angular/material/expansion';
// import { MapsAPILoader } from '@agm/core';
import {} from 'googlemaps';

@Component({
  selector: 'app-listing-details',
  templateUrl: './listing-details.component.html',
  styleUrls: ['./listing-details.component.scss']
})
export class ListingDetailsComponent implements OnInit {

  constructor(private route: ActivatedRoute, public listingService : ListingService, public gallery: Gallery,
    private ngZone: NgZone) {}
  items: GalleryItem[];
  // itemData;
  id:string;
  showData=false;
  src;
  listing;
  dataPromise:Promise<Boolean>;
  panelOpenState = false;
  house=faHome;
  clipboard=faClipboardList;
  table=faTable;
  bed=faBed;
  bath=faBath;
  ruler=faRuler;
  dollar=faDollarSign;
  expanded = true;
  @ViewChild('firstAccordion') acc: MatAccordion;
  //maps vars
  private geoCoder;
  latitude = 31.583706544091097;
  longitude = -97.30973441784992;
  zoom: number = 10;
  address: string;
  // map;
  infowindow;
  @ViewChild('map') mapElement: any;
  map: google.maps.Map;
  @ViewChildren("map")
    public mapElements: QueryList<any>


  getFeature(feature){
    return feature!=null ? feature.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/,/g, ', ') : null;
  }
  getArray(list){
    return list.split(',').filter(item => item !== 'None');;
  }
  getBaths(full, half){
    return parseInt(full)+(parseInt(half)*.5);
  }
  getPriceChange(current, original){
    if(original>current){
      return "-"+(original-current).toString();
    }
    else{
      return "+"+(current-original).toString();
    }
  }
  isPriceMore(current, original){
    if(original>current){
      return false;
    }
    else{
      return true;
    }
  }
  toggleExpand(){
    this.expanded=!this.expanded;
    if(this.expanded){
      this.acc.openAll();
    }
    else{
      this.acc.closeAll();
    }
  }
  public onMapReady(e) {
    // this.map = e;
    // this.mapsAPILoader.load().then(() => {
      // this.setCurrentLocation();
      // this.geoCoder = new google.maps.Geocoder;

          // var placeService = new google.maps.places.PlacesService(this.map);
          // var request = {
          //   query: this.listing.UnparsedAddress+" "+this.listing.City+", "+this.listing.StateOrProvince+" "+this.listing.PostalCode,
          //   fields: ['name', 'geometry'],
          // };
          // placeService.findPlaceFromQuery(request, (results, status) => { 
          //   if (status === google.maps.places.PlacesServiceStatus.OK) {
          //     // for (var i = 0; i < results.length; i++) {
          //     //   createMarker(results[i]);
          //     // }
          //     // map.setCenter(results[0].geometry.location);
          //     if(results[0].geometry != undefined || results[0].geometry != null){
          //       this.latitude = results[0].geometry.location.lat();
          //       this.longitude = results[0].geometry.location.lng();
          //       console.log(this.map)
          //       this.zoom = 15;
          //       // console.log(this.latitude);
          //       const streetView = this.map.getStreetView();
          //       console.log(streetView)
 
          //       streetView.setOptions({
          //         position: { lat: this.latitude, lng: this.longitude },
          //         pov: { heading: 70, pitch: -10 },
          //       });
          //       if(streetView.projection!=undefined){
          //         streetView.setVisible(true);
          //       }
                
          //     }
              
          //   }
          // });
  // });
  }

  ngOnInit(): void {
    //set listings
    this.id = this.route.snapshot.paramMap.get('id');
    this.listingService.getListing(parseInt(this.id), true).subscribe(listing=>{
      this.listing=listing['value'][0];
      console.log(listing)
      this.src= this.listing['Media'][0]['MediaURL'];
        this.items=this.listing['Media'].map(item =>
          new ImageItem({ src: item['MediaURL'], thumb: item['MediaURL'] })
        );
  
        // Load items into the lightbox
       this.basicLightboxExample();
   
       // Load item into different lightbox instance
       // With custom gallery config
       this.withCustomGalleryConfig();
       this.dataPromise=Promise.resolve(true);
       
       
      
    },
    error => {
      console.log(error)
    }); 
    
    

  }
  ngAfterViewInit(){
    this.mapElements.changes.subscribe((comps: QueryList <any>) =>
        {
            this.mapElement = comps.first;
            
            this.map = new google.maps.Map(this.mapElement.nativeElement);
            var placeService = new google.maps.places.PlacesService(this.map);
          var request = {
            query: this.listing.UnparsedAddress+" "+this.listing.City+", "+this.listing.StateOrProvince+" "+this.listing.PostalCode,
            fields: ['name', 'geometry'],
          };
          placeService.findPlaceFromQuery(request, (results, status) => { 
            if (status === google.maps.places.PlacesServiceStatus.OK) {

              if(results[0].geometry != undefined || results[0].geometry != null){
                this.latitude = results[0].geometry.location.lat();
                this.longitude = results[0].geometry.location.lng();
                this.zoom = 15;
                // console.log(this.latitude);
                // const streetView = this.map.getStreetView();
                // console.log(streetView)
 
                // streetView.setOptions({
                //   position: { lat: this.latitude, lng: this.longitude },
                //   pov: { heading: 70, pitch: -10 },
                // });
                // if(streetView.!=undefined){
                //   streetView.setVisible(true);
                // }
                const mapProperties = {
                  center: new google.maps.LatLng(this.latitude, this.longitude),
                  zoom: 15,
                  mapTypeId: google.maps.MapTypeId.ROADMAP,
                  mapId: "6dd924bb1e4df7c5"
                };
                
                this.map = new google.maps.Map(this.mapElement.nativeElement,    mapProperties);
                var marker = new google.maps.Marker({
                  position: new google.maps.LatLng(this.latitude, this.longitude),
                  map: this.map,
                  title: this.listing.UnparsedAddress
                });
                //END IF
              }
              
            }
            
          });
        });
    
  }

  basicLightboxExample() {
    this.gallery.ref().load(this.items);
  }

  /**
   * Use custom gallery config with the lightbox
   */
  withCustomGalleryConfig() {

    // 2. Get a lightbox gallery ref
    const lightboxGalleryRef = this.gallery.ref('anotherLightbox');

    // (Optional) Set custom gallery config to this lightbox
    lightboxGalleryRef.setConfig({
      imageSize: ImageSize.Cover,
      thumbPosition: ThumbnailsPosition.Top
    });

    // 3. Load the items into the lightbox
    lightboxGalleryRef.load(this.items);
  }
  getDOM(){
    return this.listingService.getDOM(this.listing.OnMarketTimestamp);
  }
}

  

