<div class=contact>
    <div class="map">
        <!-- <agm-map [latitude]="lat" [longitude]="lng"  (mapReady)="mapReady($event)" >
            <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
        </agm-map> -->
        <div #map class="map-container" ></div>
    </div>
    <div class=info>
        <h1>Contact Information</h1>
        <div class="info-row">
            <fa-icon class="contact-icon fa-solid fa-envelope" [icon]="envelope" size="md"></fa-icon><span class=email>me@EstherEaster.com</span>
        </div>
        <div class=info-row><fa-icon class="contact-icon fa-solid fa-mobile" [icon]="phone" size="md"></fa-icon><span class=phone>(254)-723-4395 (Voice/Text)</span></div>
        <div class=info-row><fa-icon class="contact-icon fa-solid fa-location" [icon]="location" size="md"></fa-icon><div class=address><span>8534 N Hwy 6, Suite A</span><span>Waco Texas, 76712</span></div></div>

        <div class=contact-form [formGroup]="contactForm">
            <h2>Send Message to Esther</h2>
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Subject</mat-label>
                <input matInput required formControlName="subject" placeholder="Email to Esther">
                <mat-error *ngIf="contactForm.get('subject').errors && 
                contactForm.get('subject').hasError('required')">Please enter a subject for your email</mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Cell</mat-label>
                <input matInput required formControlName="cell" placeholder="(123) 456-7890">
                <mat-error *ngIf="contactForm.get('cell').errors && 
                contactForm.get('cell').hasError('required')">Please enter a contact number for follow-up</mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Message</mat-label>
            <textarea matInput required formControlName="body" placeholder="Email Body" cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="8"></textarea>
            <mat-error *ngIf="contactForm.get('body').errors && 
            contactForm.get('body').hasError('required')">Please enter a body for your email</mat-error>
            </mat-form-field>
            <button mat-raised-button color=primary [disabled]="contactForm.invalid" (click)="goToUrl()">Draft Email to Esther</button>
        </div>
    </div>
</div>