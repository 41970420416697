<div class="footer">
    <div class=footer-row>
        <div class=column>
            <a class="menu-item" routerLink="/home" routerLinkActive="active">Home</a>
            <a class="menu-item" routerLink="/listings" routerLinkActive="active">Listings</a>
            <a class="menu-item" routerLink="/about" routerLinkActive="active">About</a>
            <a class="menu-item" routerLink="/contact" routerLinkActive="active">Contact</a>
            <a class="menu-item" (click)="openDisclosure()">Consumer Protection Notice</a>
            <a class="menu-item" (click)="openInfo()">Information About Brokerage Services</a>
        </div>
        <div class="column socials">
            <a href="https://www.facebook.com/EstherEasterRealtor/" ><fa-icon class="social-icon fb" [icon]="facebook" size="md"></fa-icon></a>
            <a href="https://twitter.com/realtorgirltx"><fa-icon class="social-icon twitter" [icon]="twitter" size="md"></fa-icon></a>
            <a href="https://www.linkedin.com/in/esther-easter-796414ab"><fa-icon class="social-icon linkedin" [icon]="linkedin" size="md"></fa-icon></a>
            <a href="https://www.youtube.com/channel/UCxNTBSuIhVlPQUQWdEe_ugw"><fa-icon class="social-icon yt" [icon]="youtube" size="md"></fa-icon></a>
        </div>
        <div class=column>
            <div>Double E Properties</div>
            <div class="contact">Esther Easter, Broker</div>
            <div class="contact">8534 N. Hwy 6, Suite A</div>    
            <div class="contact">Waco, TX  76712</div>    
            <div class="contact">254-723-4395</div>
        </div>
    </div>
    <div class=copyright>© Copyright - Double E Properties</div>
</div>

